import { default as _summerf3DqSCcOzuMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/_summer.vue?macro=true";
import { default as index12pqbsb8EvMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/about/index.vue?macro=true";
import { default as _91slug_93nRvmBRvRy7Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/authors/[slug].vue?macro=true";
import { default as indexbivIVNeAxZMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/authors/index.vue?macro=true";
import { default as _91slug_935J6HOEyRKqMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/collections/[slug].vue?macro=true";
import { default as indexSR0bZu3hmMMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/collections/index.vue?macro=true";
import { default as indexZZFFSKMYZoMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/index.vue?macro=true";
import { default as _91slug_93nZWT7OrLVdMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_article/[slug].vue?macro=true";
import { default as _91slug_933dfpR4h9qjMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_heraldic/[slug].vue?macro=true";
import { default as _91slug_93CjesUHmzOVMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_route/[slug].vue?macro=true";
import { default as _91paragraph_93BdsdEQ2mAMMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph].vue?macro=true";
import { default as indexPEPwYRuT5nMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/index.vue?macro=true";
import { default as destinationSTd5J6tFrWMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination.vue?macro=true";
import { default as eventsjZUDooJGqOMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/events.vue?macro=true";
import { default as gpt_45chatTZAO8jowhZMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/gpt-chat.vue?macro=true";
import { default as indexbBi1UsZpa7Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/index.vue?macro=true";
import { default as library8X4rVybvxNMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/library.vue?macro=true";
import { default as _91slug_931Qw9e8sqdHMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/listen/courses/[slug].vue?macro=true";
import { default as indexR69aktwTTqMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/listen/courses/index.vue?macro=true";
import { default as _91slug_93cfaicipBSJMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/[slug].vue?macro=true";
import { default as indexEhhZAowdltMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/index.vue?macro=true";
import { default as _91slug_93fyn5NY6pIcMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/magazine/article/[slug].vue?macro=true";
import { default as _91slug_93rQR4mXu3IVMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/magazine/release/[slug].vue?macro=true";
import { default as myhistory6jzp4KB5eyMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/myhistory.vue?macro=true";
import { default as indexhBbsK99zujMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/presentation/index.vue?macro=true";
import { default as privacy_policy_application5uBkrBmLfcMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/privacy_policy_application.vue?macro=true";
import { default as privacy_policyl8qi4SPCMdMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/privacy_policy.vue?macro=true";
import { default as account4d2lcHABC4Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/profile/account.vue?macro=true";
import { default as bookmarksTwLb1eFk9ZMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/profile/bookmarks.vue?macro=true";
import { default as indexzha7HXTZ1tMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/profile/index.vue?macro=true";
import { default as results9RTDWicuGaMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/profile/results.vue?macro=true";
import { default as indexZFFwsc5hAzMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/projects/index.vue?macro=true";
import { default as _91slug_93IGnDongRPwMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/articles/[slug].vue?macro=true";
import { default as indexQZgUxjSAkJMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/articles/index.vue?macro=true";
import { default as _91slug_93p5xkZxfLLTMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/biographies/[slug].vue?macro=true";
import { default as indexXlFaGgZEMmMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/biographies/index.vue?macro=true";
import { default as _91document_93n9k09jLdDOMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/[document].vue?macro=true";
import { default as indexrrlI7T0eImMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/index.vue?macro=true";
import { default as indexl6PhSzLR6yMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/documents/index.vue?macro=true";
import { default as _91slug_93ovND6aJdgPMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/monuments/[slug].vue?macro=true";
import { default as indexxPYXw8RL4BMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/monuments/index.vue?macro=true";
import { default as _91slug_93nWcCvG0S4xMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/news/[slug].vue?macro=true";
import { default as indexOHDMDLJtWPMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/news/index.vue?macro=true";
import { default as _91slug_93Wpnvkv26uzMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/science/[slug].vue?macro=true";
import { default as indexoifdBdGqbdMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/science/index.vue?macro=true";
import { default as search9RsgnDsfabMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/search.vue?macro=true";
import { default as _91slug_9348nXdF8KolMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/tags/[slug].vue?macro=true";
import { default as _91chapter_9313grnLwNu1Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue?macro=true";
import { default as _91slug_93EFdmQcZqU9Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue?macro=true";
import { default as _91slug_93jafqsovy2FMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue?macro=true";
import { default as _91slug_932dDFHj2Bn0Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/book/[slug].vue?macro=true";
import { default as _91slug_93p6lB6TklCrMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue?macro=true";
import { default as indexVWxmPaugQGMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue?macro=true";
import { default as _91slug_9332QeDSIy62Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue?macro=true";
import { default as _91slug_93Ro965Du8t8Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue?macro=true";
import { default as _91category_93LsuAeWe275Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category].vue?macro=true";
import { default as indexOinFfQS5AbMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/index.vue?macro=true";
import { default as _91parent_93kFGbPteWaMMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent].vue?macro=true";
import { default as _91slug_93taG0ANkpECMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/glossary/[slug].vue?macro=true";
import { default as indexjBbo8SjvtMMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/index.vue?macro=true";
import { default as teacherTFV98pK3PMMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher.vue?macro=true";
import { default as _91slug_93mmUPotivw5Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/tests/[slug].vue?macro=true";
import { default as indexCzTtAWvTFrMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/tests/index.vue?macro=true";
import { default as indexoe5u5e5S6MMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/timeline/index.vue?macro=true";
import { default as victory_45dayXlHpuYC3ADMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/victory-day.vue?macro=true";
import { default as _91slug_93RZum26KxtmMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/courses/[slug].vue?macro=true";
import { default as indexQ54EE4NzKgMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/courses/index.vue?macro=true";
import { default as _91slug_93Ufq395ygMSMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/films/[slug].vue?macro=true";
import { default as indexy7tctDJrMRMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/films/index.vue?macro=true";
import { default as _91slug_93TaB7o1XXcJMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/[slug].vue?macro=true";
import { default as indexbjBfc6rR0KMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/index.vue?macro=true";
import { default as indexI1UVmm6spVMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/memdates/index.vue?macro=true";
import { default as _91slug_93wHA38iziidMeta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/unknown-war/[slug].vue?macro=true";
import { default as index2eYVIt7gx6Meta } from "/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/unknown-war/index.vue?macro=true";
export default [
  {
    name: "_summer",
    path: "/_summer",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/_summer.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/about/index.vue")
  },
  {
    name: "authors-slug",
    path: "/authors/:slug()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/authors/[slug].vue")
  },
  {
    name: "authors",
    path: "/authors",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/authors/index.vue")
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    meta: _91slug_935J6HOEyRKqMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/collections/[slug].vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/collections/index.vue")
  },
  {
    name: destinationSTd5J6tFrWMeta?.name,
    path: "/destination",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination.vue"),
    children: [
  {
    name: _91paragraph_93BdsdEQ2mAMMeta?.name,
    path: ":paragraph()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph].vue"),
    children: [
  {
    name: "destination-paragraph",
    path: "",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/index.vue")
  },
  {
    name: "destination-paragraph-region_article-slug",
    path: "region_article/:slug()",
    meta: _91slug_93nZWT7OrLVdMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_article/[slug].vue")
  },
  {
    name: "destination-paragraph-region_heraldic-slug",
    path: "region_heraldic/:slug()",
    meta: _91slug_933dfpR4h9qjMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_heraldic/[slug].vue")
  },
  {
    name: "destination-paragraph-region_route-slug",
    path: "region_route/:slug()",
    meta: _91slug_93CjesUHmzOVMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/[paragraph]/region_route/[slug].vue")
  }
]
  },
  {
    name: "destination",
    path: "",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/destination/index.vue")
  }
]
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/events.vue")
  },
  {
    name: "gpt-chat",
    path: "/gpt-chat",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/gpt-chat.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexbBi1UsZpa7Meta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/index.vue")
  },
  {
    name: "library",
    path: "/library",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/library.vue")
  },
  {
    name: "listen-courses-slug",
    path: "/listen/courses/:slug()",
    meta: _91slug_931Qw9e8sqdHMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/listen/courses/[slug].vue")
  },
  {
    name: "listen-courses",
    path: "/listen/courses",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/listen/courses/index.vue")
  },
  {
    name: "listen-lectures-slug",
    path: "/listen/lectures/:slug()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/[slug].vue")
  },
  {
    name: "listen-lectures",
    path: "/listen/lectures",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/index.vue")
  },
  {
    name: "magazine-article-slug",
    path: "/magazine/article/:slug()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/magazine/article/[slug].vue")
  },
  {
    name: "magazine-release-slug",
    path: "/magazine/release/:slug()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/magazine/release/[slug].vue")
  },
  {
    name: "myhistory",
    path: "/myhistory",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/myhistory.vue")
  },
  {
    name: "presentation",
    path: "/presentation",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/presentation/index.vue")
  },
  {
    name: "privacy_policy_application",
    path: "/privacy_policy_application",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/privacy_policy_application.vue")
  },
  {
    name: "privacy_policy",
    path: "/privacy_policy",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/privacy_policy.vue")
  },
  {
    name: "profile-account",
    path: "/profile/account",
    meta: account4d2lcHABC4Meta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/profile/account.vue")
  },
  {
    name: "profile-bookmarks",
    path: "/profile/bookmarks",
    meta: bookmarksTwLb1eFk9ZMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/profile/bookmarks.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexzha7HXTZ1tMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/profile/index.vue")
  },
  {
    name: "profile-results",
    path: "/profile/results",
    meta: results9RTDWicuGaMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/profile/results.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/projects/index.vue")
  },
  {
    name: "read-articles-slug",
    path: "/read/articles/:slug()",
    meta: _91slug_93IGnDongRPwMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/articles/[slug].vue")
  },
  {
    name: "read-articles",
    path: "/read/articles",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/articles/index.vue")
  },
  {
    name: "read-biographies-slug",
    path: "/read/biographies/:slug()",
    meta: _91slug_93p5xkZxfLLTMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/biographies/[slug].vue")
  },
  {
    name: "read-biographies",
    path: "/read/biographies",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/biographies/index.vue")
  },
  {
    name: "read-documents-slug-document",
    path: "/read/documents/:slug()/:document()",
    meta: _91document_93n9k09jLdDOMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/[document].vue")
  },
  {
    name: "read-documents-slug",
    path: "/read/documents/:slug()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/index.vue")
  },
  {
    name: "read-documents",
    path: "/read/documents",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/documents/index.vue")
  },
  {
    name: "read-monuments-slug",
    path: "/read/monuments/:slug()",
    meta: _91slug_93ovND6aJdgPMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/monuments/[slug].vue")
  },
  {
    name: "read-monuments",
    path: "/read/monuments",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/monuments/index.vue")
  },
  {
    name: "read-news-slug",
    path: "/read/news/:slug()",
    meta: _91slug_93nWcCvG0S4xMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/news/[slug].vue")
  },
  {
    name: "read-news",
    path: "/read/news",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/news/index.vue")
  },
  {
    name: "read-science-slug",
    path: "/read/science/:slug()",
    meta: _91slug_93Wpnvkv26uzMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/science/[slug].vue")
  },
  {
    name: "read-science",
    path: "/read/science",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/read/science/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: search9RsgnDsfabMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/search.vue")
  },
  {
    name: "tags-slug",
    path: "/tags/:slug()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/tags/[slug].vue")
  },
  {
    name: teacherTFV98pK3PMMeta?.name,
    path: "/teacher",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher.vue"),
    children: [
  {
    name: _91parent_93kFGbPteWaMMeta?.name,
    path: ":parent()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent].vue"),
    children: [
  {
    name: _91category_93LsuAeWe275Meta?.name,
    path: ":category()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category].vue"),
    children: [
  {
    name: "teacher-parent-category-chapter",
    path: ":chapter()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue")
  },
  {
    name: "teacher-parent-category-article-slug",
    path: "article/:slug()",
    meta: _91slug_93EFdmQcZqU9Meta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue")
  },
  {
    name: "teacher-parent-category-audiolecture-slug",
    path: "audiolecture/:slug()",
    meta: _91slug_93jafqsovy2FMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue")
  },
  {
    name: "teacher-parent-category-book-slug",
    path: "book/:slug()",
    meta: _91slug_932dDFHj2Bn0Meta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/book/[slug].vue")
  },
  {
    name: "teacher-parent-category-chapter-slug",
    path: "chapter/:slug()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue")
  },
  {
    name: "teacher-parent-category",
    path: "",
    meta: indexVWxmPaugQGMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue")
  },
  {
    name: "teacher-parent-category-test-slug",
    path: "test/:slug()",
    meta: _91slug_9332QeDSIy62Meta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue")
  },
  {
    name: "teacher-parent-category-videolecture-slug",
    path: "videolecture/:slug()",
    meta: _91slug_93Ro965Du8t8Meta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue")
  }
]
  },
  {
    name: "teacher-parent",
    path: "",
    meta: indexOinFfQS5AbMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/index.vue")
  }
]
  },
  {
    name: "teacher-glossary-slug",
    path: "glossary/:slug()",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/glossary/[slug].vue")
  },
  {
    name: "teacher",
    path: "",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/teacher/index.vue")
  }
]
  },
  {
    name: "tests-slug",
    path: "/tests/:slug()",
    meta: _91slug_93mmUPotivw5Meta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/tests/[slug].vue")
  },
  {
    name: "tests",
    path: "/tests",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/tests/index.vue")
  },
  {
    name: "timeline",
    path: "/timeline",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/timeline/index.vue")
  },
  {
    name: "victory-day",
    path: "/victory-day",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/victory-day.vue")
  },
  {
    name: "watch-courses-slug",
    path: "/watch/courses/:slug()",
    meta: _91slug_93RZum26KxtmMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/courses/[slug].vue")
  },
  {
    name: "watch-courses",
    path: "/watch/courses",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/courses/index.vue")
  },
  {
    name: "watch-films-slug",
    path: "/watch/films/:slug()",
    meta: _91slug_93Ufq395ygMSMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/films/[slug].vue")
  },
  {
    name: "watch-films",
    path: "/watch/films",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/films/index.vue")
  },
  {
    name: "watch-lectures-slug",
    path: "/watch/lectures/:slug()",
    meta: _91slug_93TaB7o1XXcJMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/[slug].vue")
  },
  {
    name: "watch-lectures",
    path: "/watch/lectures",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/index.vue")
  },
  {
    name: "watch-memdates",
    path: "/watch/memdates",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/memdates/index.vue")
  },
  {
    name: "watch-unknown-war-slug",
    path: "/watch/unknown-war/:slug()",
    meta: _91slug_93wHA38iziidMeta || {},
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/unknown-war/[slug].vue")
  },
  {
    name: "watch-unknown-war",
    path: "/watch/unknown-war",
    component: () => import("/home/jetmix/delo1.jet-mix.ru/histrf-client/pages/watch/unknown-war/index.vue")
  }
]