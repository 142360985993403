import api from '@/services/api/api';

const toast = useToast();

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: useCookie('access_token').value ?? '',
    userInfo: {},
    legacyUser: false,
  }),

  actions: {
    async login(user) {
      const modalsStore = useModalsStore();
      try {
        const { data } = await api.auth.login(user);
        if (data.token) {
          await this.setLoggedIn(data.token);
          toast.success('Вы успешно авторизовались');
        } else if (data.legacy) {
          this.legacyUser = true;
          // commit('SET_LEGACY_PROPERTY');
          toast.success(
            'Наша система авторизации изменилась. На Ваш почтовый адрес отправлено письмо для восстановления пароля.',
          );
        }
        modalsStore.closeModal(null, { root: true });
        navigateTo('/profile');
      } catch (e) {
        toast.error(`${e.response.data.message}`);
      }
    },

    async setLoggedIn(token) {
      this.token = token;
      const accessToken = useCookie('access_token');
      accessToken.value = token;
      // this.$cookies.set('access_token', token); // TODO
      // http.defaults.headers.common.Authorization = `Bearer ${token}`;
      // commit('SET_TOKEN', token);
      const { data: user } = await api.user.getUserInfo();
      this.userInfo = user;
      // commit('SET_USER', user.data);
    },

    async updateUserInfo() {
      const { data: user } = await api.user.getUserInfo();
      this.userInfo = user;
    },

    async register(user) {
      try {
        const modalsStore = useModalsStore();
        const { data } = await api.auth.register(user);
        if (data.token) {
          await this.setLoggedIn(data.token);
        }
        toast.success(
          'Вы успешно зарегистрировались. На указанную Вами электронную почту отправлено письмо для подтверждения аккаунта.',
        );
        modalsStore.closeModal(null, { root: true });
        return data;
      } catch (e) {
        if (e.response.data.message === 'The given data was invalid.') {
          toast.error('Этот адрес эл. почты уже используется');
        }
        console.log(e.response.data.message);
      }
    },

    logout() {
      try {
        this.token = '';
        const accessToken = useCookie('access_token');
        accessToken.value = null;
        // this.$cookies.remove('access_token'); // TODO
        // http.defaults.headers.common.Authorization = ``;
        // commit('REMOVE_TOKEN');
        this.userInfo = {};
        // commit('REMOVE_USER');
        toast.success('Вы вышли из учетной записи.');
      } catch (e) {
        toast.error(`${e}`);
      }
    },

    forgotPassword(email) {
      try {
        const modalsStore = useModalsStore();
        api.auth.forgotPassword(email);
        modalsStore.closeModal(null, { root: true });
        toast.success(
          'Письмо для восстановления пароля отправлено на указанную Вами электронную почту.',
        );
      } catch (e) {
        toast.error(`${e}`);
      }
    },

    async resetPassword(data) {
      try {
        const modalsStore = useModalsStore();
        await api.auth.resetPassword(data);
        modalsStore.closeModal(null, { root: true });
        toast.success('Ваш пароль успешно изменен.');
      } catch (e) {
        toast.error(`${e}`);
      }
    },
  },

  getters: {
    isAuthorized: (state) => !!state.token,
    getToken: (state) => state.token,
    isLegacyUser: (state) => state.legacyUser,
    getUserInfo: (state) => state.userInfo?.data,
  },
});
