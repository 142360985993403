export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const menuStore = useMenuStore();
  const audioStore = useAudioStore();

  menuStore.closeAllMenu();

  if (process.client) document.body.style.position = '';

  const isAudioPlaying = audioStore.isPlayed;

  if (isAudioPlaying) {
    audioStore.showFixedAudioBar();
  }

  if (
    !authStore.isAuthorized &&
    to.path.startsWith('/profile') &&
    to.path !== '/profile/account'
  ) {
    return navigateTo({ name: 'index' });
  }
});
